import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.slug} className="text-black fs-2 text-decoration-none">
      {post.frontmatter.title} {false && (post.frontmatter.date)}
    </Link>
  </div>
)

export default PostLink
import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import Layout from "../components/layout"

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .//filter by slug that contains "/news/bg-news-details-"
    filter(edge => edge.node.frontmatter.slug.includes("/news/bg-news-details-"))
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <div className="container">
        <h1>Блог</h1>
        {Posts}
      </div>
    </Layout>
  )
}

export default Blog

//(sort: { order: DESC, fields: [frontmatter___date]})
export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`